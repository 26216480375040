section#footer{
  padding: 2rem;
  background: $indigo;
  @include md{
    padding: 1rem 2rem;
  }
  @include lg{
    padding: 2rem 4rem;
  }
  p{
    font-size: 0.9rem;
    color: #eee;
    margin: 0;
  }
  p.address{
    margin-top: 0.5rem;
  }
  .footer-nav{
    margin-bottom: 0.75rem;
    @include md{
      flex: 1;
      margin-bottom: 0;
    }
    a{
      font-size: 1rem;
      color: white;
      text-decoration: none;
      margin-right: 0.75rem;
    }
  }
}