header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .phone-number-mobile{
    text-align: center;
    a{
      display: inline-block;
      color: white;
      text-decoration: none;
      font-family: "Muli", sans-serif;
      font-weight: 700;
      span{
        font-size: 1.2rem;
        vertical-align: top;
      }
      i{
        font-size: 1.2rem;
        vertical-align:top;
      }
    }
  }
}
section#navbar{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5rem;
  z-index: 100;
  justify-content: center;
  position: relative;
  @include md{
    justify-content: flex-start;
  }
  &.hidden{
    position: fixed !important;
    top: -7rem !important;
  }
  &.fixed{
    top: 0 !important;
    transition: top 0.2s !important;
    background: white;
    h1.logo{
      a{
        background: url(/assets/images/octave-dark.svg) center center no-repeat;
        background-size: 100%;
        text-indent: -9999px;
        display: block;
        font-size: 2rem;
      }
    }
    nav ul li a{
      color: $grey;
    }
    nav ul li a:hover{
      color: $dark-grey;
    }
    nav ul li.phone-number{
      i{
        color: $indigo;
      }
      a{
        color: $indigo;
      }
    }
    .hamburger-button{
      color: $indigo;
    }
  }
  h1.logo{
    margin: -0.35rem 0 0 0;
    width: 7rem;
    @include md{
      margin-left: 2rem;
    }
    a{
      background: url(/assets/images/octave-white.svg) center center no-repeat;
      background-size: 100%;
      text-indent: -9999px;
      display: block;
      font-size: 2rem;
    }
  }
  .hamburger-button{
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    color: white;
    text-decoration: none;
    @include md{
      position: relative;
      top: 0;
      right: 0;
      display: none;
    }
  }
  nav{
    flex: 1;
    height: 100%;
    display: none;
    @include md{
      display: block;
    }
    ul{
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      li{
        margin-left: 3rem;
        height: 100%;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-size: 1.1rem;
          color: #bbb;
          font-family: "Muli", sans-serif;
          font-weight: 200;
          text-decoration: none;
        }
        a:hover{
          color: #aaa;
        }
      }
      li.phone-number{
        i{
          color: white;
          margin-right: 0.5rem;
        }
        span{
          border-bottom: 6px solid $green;
          box-sizing: border-box;
          padding: 2px 0;
          position: relative;
          top: 2px;
        }
        a{
          padding: 0 2rem 0 0;
          color: white;
          font-weight: 700;
          //background: #3EA97D;
        }
        a:hover{
          //background: #46B688;
        }
      }
    }
  }
}

#fullscreen-menu{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $indigo;
  opacity: 0.98;
  z-index: 1000;
  .close-button{
    position: fixed;
    top: 0;
    right: 0;
    padding: 0 2rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    i{
      color: white;
    }
  }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    li{
      text-align: center;
      padding: 2rem 0;
      margin: 0 3rem;
      border-bottom: 1px solid white;
      &:last-child{
        border-bottom: 0;
      }
      a{
        color: white;
        text-decoration: none;
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }
}
