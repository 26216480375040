section#cover{
  background: $indigo;
  height: 90%;
  box-sizing: border-box;
  padding: 5rem 2rem;
  text-align: center;
  overflow:hidden;
  .scroll-for-more{
    display: none;
    @include md{
      display: inline-flex;
    }
  }
  h2.strapline{
    font-family: "Muli", sans-serif;
    font-size: 1.9rem;
    color: white;
    text-align: center;
    width: 100%;
    margin: 3rem auto 3rem;
    @include sm{
      font-size: 2.5rem;
      max-width: 700px;
    }
    @include lg{
      max-width: 900px;
      font-size: 3rem;
    }
  }
  .gallery{
    margin-top: 2rem;
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include md{
      margin-top: 4rem;
    }
    img{
      position: absolute;
      width: 100%;
      max-width: 60em;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      opacity: 0;
    }
    img.visible{
      opacity: 1;
    }
    img#hacw{
      margin-top: 1.5rem;
      @include md{
        margin-top: 5rem;
      }
    }

  }
}

section#cover.websites{
  .scroll-for-more{
    display: inline-flex;
  }
  .gallery img{
    max-width: 100em;
  }

}
