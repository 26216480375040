.button{
  border: 0.25rem solid $indigo;
  padding: 1rem 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $indigo;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  font-size: 1rem;
  transition: 0.25s;
}
.button.button-white{
  border-color: white;
  color: white;
}
.button.button-white:hover{
  background-color: white;
  color: $indigo;
}
.button:hover{
  background: $indigo;
  color: white;
}
.button.icon-right{
  padding-left: 2rem;
  i{
    margin-left: 0.3rem;
  }
}
.button.icon-left{
  padding-right: 2rem;
  i{
    margin-right: 0.3rem;
  }
}


h2{
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: black;
  font-size: 2rem;
  margin: 0 0 1rem;
  @include lg{
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
  }
}
h3{
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: $grey;
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
  @include lg{
    font-size: 2rem;
    margin: 0 0 0.5rem;
  }
}
p{
  line-height: 1.5;
  font-size: 1.2rem;
  margin: 0 0 1.5rem;
  color: $grey;
  @include lg{
    font-size: 1.5rem;
    margin: 0 0 2rem;
  }
}
