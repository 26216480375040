@import "normalize.css";
@import "vars";

html{
  background-color: $indigo;
}
html,body{
  height: 100%;
}
body{
  font-size: 62.5%;
  font-family: "Muli", sans-serif;
  line-height: 1.3;
}

::selection {
  background: $green; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $green; /* Gecko Browsers */
}

@import "common";
@import "header";
@import "cover";
@import "intro";
@import "projects";
@import "contact";
@import "footer";
