section#intro{
  padding: 2rem;
  background: white;
  p{
    @include lg{
      max-width: 1000px;
    }
  }
  p:last-child{
    margin: 0;
  }
  @include lg{
    padding: 4rem;
  }
}