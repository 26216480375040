section#contact{
  padding: 2rem;
  background: white;
  h2{
    margin: 0 0 0.5rem;
  }
  @include lg{
    padding: 4rem;
  }
  .contact-buttons{
    display: flex;
    flex-direction: column;
    .button{
      margin-bottom: 1rem;
      @include md{
        font-size: 1.3rem;
        i{
          font-size: 1.6rem;
          margin-right: 0.5rem;
        }
      }
    }
    @include lg{
      flex-direction: row;
      .button{
        margin-right: 1rem;
      }
    }
  }
}